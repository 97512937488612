import React, { useState, useEffect  } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import FileIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Prism from 'prismjs'; // Importamos PrismJS
import 'prismjs/themes/prism-tomorrow.css';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import "./App.css";

const FileTree = ({ data, isOpen = false, onFileClick }) => {
  const [open, setOpen] = useState(isOpen);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleFileClick = (file) => {
    onFileClick(file);
  };



  const renderTree = (node, isOpen) => (
    <div
      style={{
        marginLeft: '1rem',
        padding: '0.5rem',
        color: '#fff',
        transition: 'all 0.3s ease',
        borderLeft: node.type === 'folder' ? 'none' : 'none',
        transform: open ? 'translateX(0)' : 'translateX(-20px)',
        opacity: open ? 1 : 0.7,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {node.children && node.name !== 'Number_Bot' && (open ? <ExpandMoreIcon onClick={handleToggle} style={{ color: '#FFEB3B' }} /> : <ChevronRightIcon onClick={handleToggle} style={{ color: '#FFEB3B' }} />)}
        {node.type === 'folder' ? (
          <FolderIcon style={{ marginRight: '0.5rem', color: '#3F51B5' }} />
        ) : (
          <FileIcon style={{ marginRight: '0.5rem', color: '#009688' }} />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div onClick={() => node.type === 'file' && handleFileClick(node.file)}>{node.name}</div>
          {node.description && <small style={{ color: '#aaa' }}>{node.description}</small>}
        </div>
      </div>
      {node.children && open && (
        <div
          style={{
            marginLeft: '1rem',
            padding: '0.5rem',
            color: '#fff',
            transition: 'all 0.3s ease',
            borderLeft: '1px solid #ccc',
            transform: open ? 'translateX(0)' : 'translateX(-20px)',
            opacity: open ? 1 : 0.7,
          }}
        >
          {node.children.map((child, index) => (
            <div key={index}>
              <FileTree data={child} isOpen={node.name === 'src' ? false : isOpen} onFileClick={onFileClick} className="file-tree" />
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return renderTree(data, isOpen);
};

const App = () => {
  const [fileContent, setFileContent] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileClick = async (fileName) => {
    try {
      // Fetch the content of the Markdown file from the public directory
      const response = await fetch(`/archivos/${fileName}`);
      console.log(response);
      if (response.ok) {
        const text = await response.text();
        setFileContent(text); // Store the Markdown content
      } else {
        setFileContent(`Error: Could not fetch ${fileName}`);
      }
      setOpenPopup(true); // Open the popup to display content
    } catch (error) {
      setFileContent(`Error fetching file: ${error.message}`);
      setOpenPopup(true);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleCopyContent = () => {
    navigator.clipboard.writeText(fileContent)
      .then(() => {
        // Reemplaza el alert con una notificación emergente
        enqueueSnackbar('Contenido copiado al portapapeles!', { variant: 'success' });
      })
      .catch((err) => {
        console.error("Error al copiar al portapapeles: ", err);
        enqueueSnackbar('Hubo un error al copiar el contenido.', { variant: 'error' });
      });
  };

  const folderStructure = {
    name: '🌸 Amira 🌸',
    type: 'folder',
    description: 'Directorio principal de 🌸 Amira 🌸',
    children: [
      { name: '.env', type: 'file', file: 'env.md', description: 'Archivo de variables de entorno' },
      { name: 'package.json', type: 'file', file: 'package.json.md', description: 'Archivo de configuración de paquetes' },
      {
        name: 'src',
        type: 'folder',
        description: 'Directorio que contiene el código fuente del bot',
        children: [
          { name: 'index.js', type: 'file', file: 'index.js.md', description: 'Archivo principal del código' },
          {
            name: 'comandos',
            type: 'folder',
            description: 'Directorio que contiene los comandos del bot',
            children: [
              {
                name: 'Dueño',
                type: 'folder',
                description: 'Comandos exclusivos para el dueño del bot',
                children: [
                  { name: 'donation.js', type: 'file', file: 'donation.js.md', description: 'Comando para donaciones' },
                  { name: 'reglas.js', type: 'file', file: 'reglas.js.md', description: 'Comando para ver las reglas' },
                  { name: 'vote.js', type: 'file', file: 'vote.js.md', description: 'Comando para votar' },
                ],
              },
            ],
          },
          {
            name: 'eventos',
            type: 'folder',
            description: 'Eventos del bot',
            children: [
              {
                name: 'client',
                type: 'folder',
                description: 'Eventos del cliente del bot',
                children: [
                  { name: 'ready.js', type: 'file', file: 'ready.js.md', description: 'Evento cuando el cliente está listo' },
                ],
              },
              {
                name: 'guild',
                type: 'folder',
                description: 'Eventos del servidor del bot',
                children: [
                  { name: 'interactionCreate.js', type: 'file', file: 'interactionCreate.js.md', description: 'Evento cuando se recibe una interacción' },
                  { name: 'messageCreate.js', type: 'file', file: 'messageCreate.js.md', description: 'Evento cuando se recibe un mensaje' },
                ],
              },
            ],
          },
          {
            name: 'handlers',
            type: 'folder',
            description: 'Manejadores del bot',
            children: [
              { name: 'antiCrash.js', type: 'file', file: 'antiCrash.js.md', description: 'Manejador anti crash' },
              { name: 'autorol.js', type: 'file', file: 'autorol2.js.md', description: 'Manejador de autorrol' },
              { name: 'ayuda.js', type: 'file', file: 'ayuda.js.md', description: 'Manejador de ayuda' },
              { name: 'bienvenidas.js', type: 'file', file: 'bienvenidas.js.md', description: 'Manejador de bienvenidas' },
              { name: 'channelvoice.js', type: 'file', file: 'channelvoice.js.md', description: 'Manejador de canales de voz' },
              { name: 'conteo.js', type: 'file', file: 'conteo.js.md', description: 'Manejador de conteo' },
              { name: 'distube.js', type: 'file', file: 'distube.js.md', description: 'Manejador de Distube' },
              { name: 'messages.js', type: 'file', file: 'messages.js.md', description: 'Manejador de mensajes' },
              { name: 'morals.js', type: 'file', file: 'morals.js.md', description: 'Manejador de moralejas' },
              { name: 'niveles.js', type: 'file', file: 'niveles.js.md', description: 'Manejador de niveles' },
              { name: 'noMentions.js', type: 'file', file: 'noMentions.js.md', description: 'Manejador de menciones' },
              { name: 'servers.js', type: 'file', file: 'servers.js.md', description: 'Manejador de servidores' },
              { name: 'sugerencias.js', type: 'file', file: 'sugerencias.js.md', description: 'Manejador de sugerencias' },
              { name: 'tickets1.js', type: 'file', file: 'tickets1.js.md', description: 'Manejador de tickets (parte 1)' },
              { name: 'tickets2.js', type: 'file', file: 'tickets2.js.md', description: 'Manejador de tickets (parte 2)' },
            ],
          },
          {
            name: 'modelos',
            type: 'folder',
            description: 'Modelos Base de Datos del bot',
            children: [
              { name: 'autorol.js', type: 'file', file: 'autorol.js.md', description: 'Modelo de autorrol' },
              { name: 'canaldevoz.js', type: 'file', file: 'canaldevoz.js.md', description: 'Modelo de canal de voz' },
              { name: 'conteo.js', type: 'file', file: 'conteo2.js.md', description: 'Modelo de conteo' },
              { name: 'economia.js', type: 'file', file: 'economia.js.md', description: 'Modelo de economía' },
              { name: 'morals.js', type: 'file', file: 'morals.js.md', description: 'Modelo de moralejas' },
              { name: 'servidor.js', type: 'file', file: 'servidor.js.md', description: 'Modelo de servidor' },
              { name: 'setups.js', type: 'file', file: 'setups.js.md', description: 'Modelo de setups' },
              { name: 'tickets.js', type: 'file', file: 'tickets.js.md', description: 'Modelo de tickets' },
              { name: 'votos-sugs.js', type: 'file', file: 'votos-sugs.js.md', description: 'Modelo de votos y sugerencias' },
              { name: 'warns.js', type: 'file', file: 'warns.js.md', description: 'Modelo de advertencias' },
            ],
          },
          {
            name: 'slashCommands',
            type: 'folder',
            description: 'Comandos slash del bot',
            children: [
              {
                name: 'Admin',
                type: 'folder',
                description: 'Comandos de administración',
                children: [
                  { name: 'ban.js', type: 'file', file: 'ban.js.md', description: 'Comando para banear a un usuario' },
                  { name: 'clear.js', type: 'file', file: 'clear.js.md', description: 'Comando para limpiar mensajes' },
                  { name: 'kick.js', type: 'file', file: 'kick.js.md', description: 'Comando para expulsar a un usuario' },
                  { name: 'nuke.js', type: 'file', file: 'nuke.js.md', description: 'Comando para "nukear" un canal' },
                  { name: 'unban.js', type: 'file', file: 'unban.js.md', description: 'Comando para desbanear a un usuario' },
                  { name: 'unwarn.js', type: 'file', file: 'unwarn.js.md', description: 'Comando para desavisar a un usuario' },
                  { name: 'warn.js', type: 'file', file: 'warn.js.md', description: 'Comando para avisar a un usuario' },
                  { name: 'warning.js', type: 'file', file: 'warning.js.md', description: 'Comando para los avisos a un usuario' },
                ],
              },
              {
                name: 'Dueño',
                type: 'folder',
                description: 'Comandos exclusivos para el dueño del bot',
                children: [
                  { name: 'anuncio.js', type: 'file', file: 'anuncio.js.md', description: 'Comando para realizar anuncios' },
                  { name: 'reload.js', type: 'file', file: 'reload.js.md', description: 'Comando para recargar el bot' },],
              },
              {
                name: 'Info',
                type: 'folder',
                description: 'Comandos de información',
                children: [
                  { name: 'avatar.js', type: 'file', file: 'avatar.js.md', description: 'Comando para ver el avatar de un usuario' },
                  { name: 'botinfo.js', type: 'file', file: 'botinfo.js.md', description: 'Comando para ver información del bot' },
                  { name: 'invitebot.js', type: 'file', file: 'invitebot.js.md', description: 'Comando para obtener enlace de invitación del bot' },
                  { name: 'level.js', type: 'file', file: 'level.js.md', description: 'Comando para ver el nivel de un usuario' },
                  { name: 'ping.js', type: 'file', file: 'ping.js.md', description: 'Comando para verificar la latencia del bot' },
                  { name: 'reportbug.js', type: 'file', file: 'reportbug.js.md', description: 'Comando para reportar errores' },
                  { name: 'userinfo.js', type: 'file', file: 'userinfo.js.md', description: 'Comando para ver información de un usuario' },
                ],
              },
              {
                name: 'Interactivos',
                type: 'folder',
                description: 'Comandos interactivos',
                children: [
                  { name: '8ball.js', type: 'file', file: '8ball.js.md', description: 'Comando de la bola mágica' },
                  { name: 'dice.js', type: 'file', file: 'dice.js.md', description: 'Comando para lanzar un dado' },
                  { name: 'hug.js', type: 'file', file: 'hug.js.md', description: 'Comando para enviar un abrazo' },
                  { name: 'invites.js', type: 'file', file: 'invites.js.md', description: 'Comando para ver invitaciones' },
                  { name: 'joke.js', type: 'file', file: 'joke.js.md', description: 'Comando para obtener un chiste' },
                  { name: 'kiss.js', type: 'file', file: 'kiss.js.md', description: 'Comando para enviar un beso' },
                  { name: 'meme.js', type: 'file', file: 'meme.js.md', description: 'Comando para obtener un meme' },
                  { name: 'shot.js', type: 'file', file: 'shot.js.md', description: 'Comando para enviar un disparo' },
                  { name: 'emoji.js', type: 'file', file: 'emoji.js.md', description: 'Comando para subir emojis al servidor' },
                ],
              },
              {
                name: 'Musica',
                type: 'folder',
                description: 'Comandos de música del bot',
                children: [
                  { name: 'play.js', type: 'file', file: 'play.js.md', description: 'Comando para reproducir música' },
                  { name: 'queue.js', type: 'file', file: 'queue.js.md', description: 'Comando para ver la lista de reproducción' },
                ],
              },
              {
                name: 'Setup',
                type: 'folder',
                description: 'Comandos de configuración',
                children: [
                  { name: 'setup-autorol.js', type: 'file', file: 'setup-autorol.js.md', description: 'Comando para configurar autorrol' },
                  { name: 'setup-bienvenida.js', type: 'file', file: 'setup-bienvenida.js.md', description: 'Comando para configurar mensaje de bienvenida' },
                  { name: 'setup-channelvoice.js', type: 'file', file: 'setup-channelvoice.js.md', description: 'Comando para configurar canales de voz' },
                  { name: 'setup-counting.js', type: 'file', file: 'setup-counting.js.md', description: 'Comando para configurar conteo' },
                  { name: 'setup-levels.js', type: 'file', file: 'setup-levels.js.md', description: 'Comando para configurar niveles' },
                  { name: 'setup-morals.js', type: 'file', file: 'setup-morals.js.md', description: 'Comando para configurar moralejas' },
                  { name: 'setup-prefix.js', type: 'file', file: 'setup-prefix.js.md', description: 'Comando para cambiar el prefijo' },
                  { name: 'setup-suggestions.js', type: 'file', file: 'setup-suggestions.js.md', description: 'Comando para configurar sugerencias' },
                  { name: 'setup-tickets.js', type: 'file', file: 'setup-tickets.js.md', description: 'Comando para configurar tickets' },
                ],
              },
            ],
          },
          {
            name: 'structures',
            type: 'folder',
            description: 'Estructura del bot',
            children: [
              { name: 'Client.js', type: 'file', file: 'Client.js.md', description: 'Implementa funcionalidades y manejo de eventos' },
              { name: 'Utils.js', type: 'file', file: 'Utils.js.md', description: 'Utilidades comunes, mejoran la modularidad del código' },
            ],
          },
          {
            name: 'utils',
            type: 'folder',
            description: 'Funciones del bot',
            children: [
              { name: 'funciones.js', type: 'file', file: 'funciones.js.md', description: 'Almacena funciones específicas y utilidades' },
            ],
          },
        ],
      }
    ]
  };
  useEffect(() => {
    // Resaltamos la sintaxis de JavaScript una vez que el contenido del archivo esté disponible
    if (fileContent) {
      Prism.highlightAll(); // Resalta todo el contenido
    }
  }, [fileContent]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Verifica si es un dispositivo móvil

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="md"
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '2rem',
          background: '#333',
          borderRadius: '8px',
        }}
      >
        <div style={{ flex: 1 }}>
          <h1 style={{ textAlign: 'center', marginBottom: '2rem', color: '#fff' }}>Listado de rutas de carpetas de 🌸 Amira 🌸</h1>
          <FileTree data={folderStructure} isOpen={true} style={{ cursor: 'url("/pointer.png"), auto' }} onFileClick={handleFileClick} />
        </div>
        <div style={{ width: '150px', height: '150px', overflow: 'hidden', borderRadius: '50%', marginLeft: '1rem' }}>
          <img
            src="https://i.imgur.com/SA71ebU.png"
            alt="Avatar"
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
          />
        </div>
      </Container>
      {/* Popup para mostrar el contenido del archivo */}
      <Dialog open={openPopup} onClose={handleClosePopup} PaperProps={{
        style: {
          backgroundColor: '#333', // Fondo oscuro
          color: '#fff', // Color de texto blanco
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', // Sombra opcional
          width: isMobile ? '100%' : '50%', // 100% de ancho en móviles, 50% en pantallas grandes
          maxWidth: '100%', // Siempre 100% de ancho máximo
          margin: '0 auto', // Centra el diálogo
        },
      }}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Contenido del archivo</span>
          <Button onClick={handleCopyContent} sx={{ color: 'rgb(244 114 182)' }}><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-copy"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /></svg></Button>
        </DialogTitle>
        <DialogContent>
          <pre
            style={{
              backgroundColor: '#333',
              borderRadius: '5px',
              fontSize: '14px',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <code className="language-javascript">{fileContent}</code> {/* Indicamos que es código JavaScript */}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} sx={{ color: 'rgb(244 114 182)' }}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Envolvemos la App en SnackbarProvider
const Root = () => (
  <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <App />
  </SnackbarProvider>
);

export default Root;
